const userData = [

    {
        id: '2182939428',
        refNo: 'BC41101068611692618821334',
        documentType: "Balance Certification",
        SerialNumber: "BC41101068611692618821334",
        customerName: "مختار حسن حسين",
        printDate: "01/03/2024 14:53:41",
        balance: "247,964.92 SAR"
    },

    {
        id: '2107806065',
        refNo: 'BC41101068611692618821334',
        documentType: "Balance Certification",
        SerialNumber: "BC41101068611692618821334",
        customerName: "طوياها مياه سلطان احمد",
        printDate: "15/02/2024 14:53:41",
        balance: "210,000 SAR"
    },
    {
        id: '2135984975',
        refNo: 'BC41101068611692618821334',
        documentType: "Balance Certification",
        SerialNumber: "BC41101068611692618821334",
        customerName: "بركة ياسين احمد",
        printDate: "03/12/2023 14:53:41",
        balance: "239,391.21 SAR"
    },
    {
        id: '2456722434',
        refNo: 'BC41101068611692618821334',
        documentType: "Balance Certification",
        SerialNumber: "BC41101068611692618821334",
        customerName: "ياسين صديق ابكر محمد",
        printDate: "30/11/2023 12:20:20",
        balance: "106,456.08 SAR"
    },
    {
        id: '2042528329',
        refNo: 'BC41101068611692618821334',
        documentType: "Balance Certification",
        SerialNumber: "BC41101068611692618821334",
        customerName: "تسفا مريام محرتاب",
        printDate: "28/11/2023 13:33:19",
        balance: "312,646.88 SAR"
    },
    {
        id: '2480984935',
        refNo: 'BC41101068611692618821334',
        documentType: "Balance Certification",
        SerialNumber: "BC41101068611692618821334",
        customerName: "احمد عبدالرحيم مطيع قايد الشجاع",
        printDate: "27/11/2023 11:12:47",
        balance: "68,426.53 SAR"
    },
    {
        id: '2135691786',
        refNo: 'BC41101068611692618821334',
        documentType: "Balance Certification",
        SerialNumber: "BC41101068611692618821334",
        customerName: "عبد الهادي موسى",
        printDate: "03/12/2023 11:12:47",
        balance: "230,103.13 SAR"
    },
    {
        id: '2182949428',
        refNo: 'BC41101068611692618821334',
        documentType: "Balance Certification",
        SerialNumber: "BC41101068611692618821334",
        customerName: "مختار حسن حسين",
        printDate: "15/01/2024 11:12:47",
        balance: "200,113.69 SAR"
    }
]
export default userData;