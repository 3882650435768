import { useNavigate } from 'react-router-dom';
import userData from './Data';
import { MyContext } from './MyContext';

const Receipt = () => {
    const id = MyContext.Provider
    const user = userData.find((user) => user.id === id);

    if (!user) {
        alert("User Not Found!")
    }

    const navigate = useNavigate();

    const goBack = () => {
        navigate("/vdd/public/verify-document/verifyDocument-details");
    }
    return (
        <div className='p-4 mt-11 md:w-[40rem] md:mx-auto w-full'>
            <div className='w-full h-20 rounded-sm bg-[#0a7161]'>
                <h1 className='p-4 text-white font-semibold'>DOCUMETN <br /> VERIFICATION</h1>

            </div>
            <div className='p-6 grid-col-2  shadow-md shadow-gray-400 mb-4'>
                <div className='col-span-1'>
                    <h1>Document type</h1>
                    { }
                    <h1 className='font-semibold'> {user.documentType}</h1>
                    <h1>Serial number</h1>
                    <h1 className='font-semibold'> {user.SerialNumber}</h1>
                    <h1>Customer name</h1>
                    <h1 className='font-semibold'> {user.customerName}</h1>
                    <h1>Print date</h1>
                    <h1 className='font-semibold'> {user.printDate}</h1>
                    <h1>Balance</h1>
                    <h1 className='font-semibold'> {user.balance}</h1>
                </div>
            </div>

            <div className='flex justify-end mt-10'>
                <button className='bg-[#8cc63f] rounded-3xl text-white font-bold h-10 w-28' onClick={goBack}>CLOSE</button>
            </div>

        </div>
    );

};

export default Receipt;