import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import userData from './Data';
import Captcha from './Captcha';
import { MyContext } from './MyContext';

const CheckDocs = () => {
    const [userId, setUserId] = useState('');
    const [refNo, setRefNo] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate(); // Get the history object

    const handleCheckDocs = () => {
        const user = userData.find(user => user.id === userId && user.refNo === refNo);
        if (user) {
            setMessage(`User found with ID: ${user.id}`);
            MyContext.Provider = userId;

            navigate("/vdd/public/verify-document/verifyDocument-receipt");

        } else {
            alert("Serial Number or ID is not correct");
        }

    };

    const cancelingALL = () => {
        setRefNo('');
        setUserId('');
    }

    return (
        <>
            <div className='flex-col items-center p-4 mt-11 bg-[#eee]'>

                <div className='md:w-[30rem] md:mx-auto w-full h-20 rounded-sm bg-[#0a7161]'>
                    <h1 className='p-4 text-white font-semibold'>DOCUMETN <br /> VERIFICATION</h1>
                </div>

                <div className='md:w-[30rem] md:mx-auto p-6 flex-col shadow-md shadow-gray-400 mb-4  bg-white'>

                    <div className=''>
                        <h1>Document Serial number <span className='text-[#d4110c]'>*</span></h1>
                        <input className='p-2 border-2 h-10 mt-1 mb-10 border-gray-400 w-full'
                            type="text"
                            value={refNo}
                            onChange={e => setRefNo(e.target.value)}
                        />
                    </div>
                    <div className=''>
                        <h1>Customer ID Number <span className='text-[#d4110c]'>*</span></h1>
                        <input className='p-2 border-2 h-10 mt-1 mb-4 border-gray-400 w-full'
                            type="text"
                            value={userId}
                            onChange={e => setUserId(e.target.value)}
                        />
                    </div>
                    <Captcha />

                </div>

                <div className='md:w-[30rem] md:mx-auto flex justify-end mb-10'>
                    <button className='bg-[#ddd] mx-4 rounded-3xl text-black font-bold h-10 w-32' onClick={cancelingALL}>CANCEL</button>
                    <button className='bg-[#8cc63f] rounded-3xl text-white font-bold h-10 w-32' onClick={handleCheckDocs}>PROCEED</button>
                    <p>{message}</p>
                </div>

            </div>
        </>
    );
};


export default CheckDocs;