// src/components/Navbar.js
import React, { useState } from 'react';
import l123 from '../images/123.svg'
import callus from '../images/callus.svg'
import call from '../images/call.svg'
import location from '../images/location.svg'

const Topbar = () => {

    return (
        <nav className="bg-[#555555]">


            <div className="flex items-center justify-between px-4 py-2 w-full">
                <div className="flex justify-center ">
                    <a
                        href="#"
                        className="text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                        <img className='h-5 w-5' src={callus} />
                    </a>
                    <a
                        href="#"
                        className="text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                        <img className='h-5 w-5' src={call} />
                    </a>
                    <a
                        href="#"
                        className="text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                        <img className='h-5 w-5' src={location} />
                    </a>
                    <a
                        href="#"
                        className="text-white px-3 py-2 rounded-md text-sm font-medium"
                    >
                        <img className='h-5 w-5' src={l123} />
                    </a>
                </div>
                <div className="self-auto">
                    <h1 className="text-white font-semibold text-lg">العربية</h1>
                </div>
            </div>

        </nav>
    );
};

export default Topbar;
