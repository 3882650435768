// src/components/Captcha.js
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Captcha = () => {
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const handleCaptchaVerification = () => {
    // This function will be called when the user successfully completes the CAPTCHA.
    setIsCaptchaVerified(true);
  };

  return (
    <div>
      <ReCAPTCHA
        sitekey="6LelB8cnAAAAAEkE9tQQPDpXu_Q6OgJn_i-vHg51"
        onChange={handleCaptchaVerification}
      />
      {isCaptchaVerified && <p>CAPTCHA verified!</p>}
    </div>
  );
};

export default Captcha;
