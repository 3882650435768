// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Topbar from './components/Topbar';
import Navbar from './components/Navbar';
import CheckDocs from './components/CheckDocs';
import Receipt from './components/Receipt';
import Footer from './components/Footer';

function App() {
  return (
    <BrowserRouter>
      <Topbar />
      <Navbar />
      <Routes>
        <Route path="/vdd/public/verify-document/verifyDocument-details" exact element={<CheckDocs />} />
        <Route path="/vdd/public/verify-document/verifyDocument-receipt" element={<Receipt />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
