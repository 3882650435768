// src/components/Footer.js
import React from 'react';
import facebook from '../images/facebook.png'
import instagram from '../images/instagram.png'
import linkedin from '../images/linkedin.png'
import youtube from '../images/youtube.png'
import twitter from '../images/twitter.png'

const Footer = () => {
    return (
        <footer className="bg-[#555555] p-2 w-full bottom-0 left-0">
            <div className="flex justify-center">
                <ul className="flex space-x-4">
                    <li>
                        <a
                            href="#"
                            className="text-white text-[0.6rem] hover:text-gray-300 transition duration-300"
                        >
                            Security
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            className="text-white text-[0.6rem] hover:text-gray-300 transition duration-300"
                        >
                            Careers
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            className="text-white text-[0.6rem] hover:text-gray-300 transition duration-300"
                        >
                            Consumer Protection Code
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            className="text-white text-[0.6rem] hover:text-gray-300 transition duration-300"
                        >
                            Banking Fees
                        </a>
                    </li>

                </ul>
            </div>

            <div className="flex justify-center">
                <ul className="flex space-x-4">
                    <li>
                        <a
                            href="#"
                            className="text-white text-[0.6rem] hover:text-gray-300 transition duration-300"
                        >
                            SNB Capital
                        </a>
                    </li>
                    <li>
                        <a
                            href="#"
                            className="text-white text-[0.6rem] hover:text-gray-300 transition duration-300"
                        >
                            Aqar AlAhli
                        </a>
                    </li>

                </ul>
            </div>
            <div className="flex justify-center mt-4 gap-4 text-center text-gray-400">
                <a href='https://twitter.com/snbalahli'>
                    <img className='h-10 w-10' src={twitter} />
                </a>
                <a href='https://www.youtube.com/user/alahlincb'>
                    <img className='h-10 w-10' src={youtube} />
                </a>
                <a href='https://www.linkedin.com/company/snbalahli?trk=top_nav_home'>
                    <img className='h-10 w-10' src={linkedin} />
                </a>
                <a href='https://www.instagram.com/snbalahli'>
                    <img className='h-10 w-10' src={instagram} />
                </a>
                <a href='https://www.facebook.com/SNBAlAhli'>
                    <img className='h-10 w-10' src={facebook} />
                </a>
            </div>

        </footer>
    );
};

export default Footer;
