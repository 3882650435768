// src/components/Navbar.js
import React, { useState } from 'react';
import enLogo from '../images/logo.png'

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                        <div className="">
                            <img className="mt-4 ml-4 h-12 w-44" src={enLogo} alt='logo' />

                        </div>
                        <div className="hidden md:block">
                            {/* Display the menu items for larger screens */}
                            <a
                                href="#"
                                className="text-black px-3 py-2 rounded-md text-sm font-medium"
                            >
                                Personal Banking
                            </a>
                            <a
                                href="#"
                                className="text-black px-3 py-2 rounded-md text-sm font-medium"
                            >
                                Business Banking
                            </a>
                            <a
                                href="#"
                                className="text-black px-3 py-2 rounded-md text-sm font-medium"
                            >
                                Investments
                            </a>
                            <a
                                href="#"
                                className="text-black px-3 py-2 rounded-md text-sm font-medium"
                            >
                                About Us
                            </a>
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        <button
                            onClick={toggleMenu}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-500"
                        >
                            <span className="sr-only">Open main menu</span>
                            <svg 
                                className="h-8 w-8 mt-4 mr-3 "
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            {/* Mobile menu */}
            <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden`}>
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    <a
                        href="#"
                        className="text-black block px-3 py-2 rounded-md text-base font-medium"
                    >
                        Personal Banking
                    </a>
                    <a
                        href="#"
                        className="text-black block px-3 py-2 rounded-md text-base font-medium"
                    >
                        Business Banking
                    </a>
                    <a
                        href="#"
                        className="text-black block px-3 py-2 rounded-md text-base font-medium"
                    >
                        Investments
                    </a>
                    <a
                        href="#"
                        className="text-black block px-3 py-2 rounded-md text-base font-medium"
                    >
                        About Us
                    </a>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
